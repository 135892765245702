import AppContext from 'context/Context';
import getPurchaseFormSettingsData from 'data/purchase-form-settings-data/purchaseFormSettingsData';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
export default function usePurchaseFormSettingsHook(type, initialize) {
  const { config, setConfig } = useContext(AppContext);
  const localStorageTargetKey = 'PURCHASE-FORM-SETTINGS';
  const contextResultTargetKey = type + '-FORM-SETTINGS';
  const data = config?.[contextResultTargetKey];

  useEffect(() => {
    if (!initialize) return;
    const settingsData = getPurchaseFormSettingsData(type);
    const storedSettings = localStorage.getItem(localStorageTargetKey);
    let thisTypeRelatedSettings;
    if (storedSettings) {
      thisTypeRelatedSettings = JSON.parse(storedSettings)?.[type];
    }

    if (!settingsData) return;

    setConfig(contextResultTargetKey, {
      ...settingsData,
      sections: Array.isArray(thisTypeRelatedSettings)
        ? [...thisTypeRelatedSettings]
        : settingsData?.sections
    });
  }, [type, localStorageTargetKey, initialize]);

  return data?.sections?.reduce((result, section) => {
    section?.data?.forEach(item => {
      result[item?.uniqueKey] = item?.show;
    });
    return result;
  }, {});
}

usePurchaseFormSettingsHook.propTypes = {
  type: PropTypes.oneOf(['PR', 'PO', 'PI', 'PD', 'DN']),
  initialize: PropTypes.bool
};
