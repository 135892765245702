import PropTypes from 'prop-types';

const detailsInputList = {
  title: 'Details line item field list',
  data: [
    {
      uniqueKey: 'item_code',
      title: 'Item Code',
      show: true
    },
    {
      uniqueKey: 'item_description',
      title: 'Item Description',
      show: true
    },
    {
      uniqueKey: 'unit',
      title: 'Unit',
      show: true
    },
    {
      uniqueKey: 'tax_group',
      title: 'Tax Group',
      show: true
    },
    {
      uniqueKey: 'unit_tax',
      title: 'Unit Tax',
      show: true
    },
    {
      uniqueKey: 'discount',
      title: 'Discount / Unit',
      show: true
    }
  ]
};

const glEntryInputList = {
  title: 'GL line item field list',
  data: [
    {
      uniqueKey: 'account_code',
      title: 'Account Code',
      show: true
    },
    {
      uniqueKey: 'cost_center',
      title: 'Cost Center',
      show: true
    },
    {
      uniqueKey: 'memo',
      title: 'Memo',
      show: true
    },
    {
      uniqueKey: 'tax_amount',
      title: 'Tax Amount',
      show: true
    }
  ]
};

const common = {
  date: {
    uniqueKey: 'date',
    title: 'Date',
    show: true
  },
  cost_center: {
    uniqueKey: 'cost_center',
    title: 'Cost Center',
    show: true
  },
  warehouse_name: {
    uniqueKey: 'warehouse_name',
    title: 'Warehouse Name',
    show: true
  },
  tax_included: {
    uniqueKey: 'tax_included',
    title: 'Tax Included',
    show: true
  },
  attach_files: {
    uniqueKey: 'attach_files',
    title: 'Attach Files',
    show: true
  },
  notes: {
    uniqueKey: 'notes',
    title: 'Notes',
    show: true
  },
  supplier_name: {
    uniqueKey: 'supplier_name',
    title: 'Supplier Name',
    show: true
  },
  supplier_reference: {
    uniqueKey: 'supplier_reference',
    title: 'Supplier Reference',
    show: true
  },
  payment_term: {
    uniqueKey: 'payment_term',
    title: 'Payment Term',
    show: true
  },
  due_date: {
    uniqueKey: 'due_date',
    title: 'Due Date',
    show: true
  },
  contact_number: {
    uniqueKey: 'contact_number',
    title: 'Contact Number',
    show: true
  },
  contact_email: {
    uniqueKey: 'contact_email',
    title: 'Contact Email',
    show: true
  },
  terms_and_conditions: {
    uniqueKey: 'terms_and_conditions',
    title: 'Terms & Conditions',
    show: true
  }
};

const dataObject = {
  PR: {
    title: 'Request',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.date,
          common.cost_center,
          common.warehouse_name,
          common.tax_included,
          common.attach_files,
          common.notes
        ]
      },
      detailsInputList
    ]
  },
  PO: {
    title: 'Order',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.payment_term,
          common.date,
          common.due_date,
          common.cost_center,
          common.warehouse_name,
          common.contact_number,
          common.contact_email,
          common.tax_included,
          common.attach_files,
          common.notes
        ]
      },
      detailsInputList
    ]
  },
  PD: {
    title: 'Delivery',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.payment_term,
          common.date,
          common.cost_center,
          common.warehouse_name,
          common.contact_number,
          common.contact_email,
          common.tax_included,
          common.attach_files,
          common.notes
        ]
      },
      detailsInputList
    ]
  },
  PI: {
    title: 'Invoice',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.supplier_reference,
          common.payment_term,
          common.date,
          common.due_date,
          common.cost_center,
          common.warehouse_name,
          common.contact_number,
          common.contact_email,
          common.tax_included,
          common.attach_files,
          common.terms_and_conditions,
          common.notes
        ]
      },
      detailsInputList,
      glEntryInputList
    ]
  },
  DN: {
    title: 'Debit Note',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.payment_term,
          common.cost_center,
          common.warehouse_name,
          common.date,
          common.due_date,
          common.contact_number,
          common.contact_email,
          common.tax_included,
          common.attach_files,
          common.notes
        ]
      },
      detailsInputList
    ]
  }
};

export default function getPurchaseFormSettingsData(type) {
  return dataObject?.[type];
}

getPurchaseFormSettingsData.propTypes = {
  type: PropTypes.oneOf(['PR', 'PO', 'PI', 'PD', 'DN'])
};
