import { showToast } from 'module/Common/Toast/toast';

export async function checkQrcodeScannedOrNot(
  userId,
  setQrCode,
  setAuthenticated,
  setModalClosingNotification
) {
  const maxRetries = 50;
  let attempts = 0;
  while (attempts < maxRetries) {
    attempts++;
    try {
      const session = await fetch(
        `${process.env.REACT_APP_WHATSAPP_BOT_URL}/session/status/${userId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      const data = await session.json();
      if (data?.success) {
        if (typeof setAuthenticated === 'function') setAuthenticated(true);
        if (typeof setQrCode === 'function') setQrCode();
        return;
      } else {
        await new Promise(resolve => setTimeout(resolve, 2000));
        continue;
      }
    } catch (error) {
      showToast(
        'Something went wrong, please refresh the page and try again.',
        'error'
      );
      return;
    }
  }
  setModalClosingNotification(true);
}

export async function getQrCodeForScanning(
  userId,
  setQrCode,
  setAuthenticated,
  setModalClosingNotification
) {
  const maxRetries = 30;
  let attempts = 0;
  while (attempts < maxRetries) {
    attempts++;
    try {
      const session = await fetch(
        `${process.env.REACT_APP_WHATSAPP_BOT_URL}/session/qr/${userId}/image`,
        {
          method: 'GET'
        }
      );
      const contentType = session.headers.get('Content-Type');
      if (contentType === 'image/png') {
        const blob = await session.blob();
        const imageUrl = URL.createObjectURL(blob);
        setQrCode(imageUrl);
        checkQrcodeScannedOrNot(
          userId,
          setQrCode,
          setAuthenticated,
          setModalClosingNotification
        );
        return;
      }
      if (contentType !== 'image/png') {
        await new Promise(resolve => setTimeout(resolve, 2000));
        continue;
      }
      showToast(
        'Unexpected content type received. Please try again later.',
        'error'
      );
      return;
    } catch (error) {
      showToast(
        'Something went wrong, please refresh the page and try again.',
        'error'
      );
      return;
    }
  }
  showToast('Unable to retrieve QR code after multiple attempts.', 'error');
}

export async function restartSession(
  userId,
  setQrCode,
  setAuthenticated,
  setModalClosingNotification
) {
  try {
    const session = await fetch(
      `${process.env.REACT_APP_WHATSAPP_BOT_URL}/session/restart/${userId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    const data = await session.json();
    if (data?.success) {
      getQrCodeForScanning(
        userId,
        setQrCode,
        setAuthenticated,
        setModalClosingNotification
      );
    }
  } catch (error) {
    showToast(
      'Something went wrong, please refresh the page and try again.',
      'error'
    );
  }
}

export async function activateSession(
  userId,
  setQrCode,
  setAuthenticated,
  setModalClosingNotification
) {
  try {
    const session = await fetch(
      `${process.env.REACT_APP_WHATSAPP_BOT_URL}/session/start/${userId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    const data = await session.json();
    if (data?.success) {
      getQrCodeForScanning(
        userId,
        setQrCode,
        setAuthenticated,
        setModalClosingNotification
      );
    } else {
      restartSession(
        userId,
        setQrCode,
        setAuthenticated,
        setModalClosingNotification
      );
    }
  } catch (error) {
    showToast(
      'Something went wrong, please refresh the page and try again.',
      'error'
    );
  }
}

export async function checkSessionActiveOrNot(
  userId,
  setAuthenticated,
  setQrCode,
  setModalClosingNotification
) {
  try {
    const session = await fetch(
      `${process.env.REACT_APP_WHATSAPP_BOT_URL}/session/status/${userId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    const data = await session.json();
    if (data?.success) {
      if (typeof setAuthenticated === 'function') setAuthenticated(true);
      if (typeof setQrCode === 'function') setQrCode();
    } else {
      activateSession(
        userId,
        setQrCode,
        setAuthenticated,
        setModalClosingNotification
      );
    }
  } catch (error) {
    showToast(
      'Something went wrong, please refresh the page and try again.',
      'error'
    );
  }
}

export function convertBlobUrlToBase64(blobUrl) {
  return new Promise((resolve, reject) => {
    fetch(blobUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.split(',')[1];
          resolve(base64String);
        };
        reader.readAsDataURL(blob);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const nameToNumber = name => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    const char = name.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return Math.abs(hash);
};

export const generateUniqueId = (uuid, name) => {
  const nameHash = nameToNumber(name);
  return `${uuid}-${nameHash}`;
};

// export async function buildSessionAndGetQrCode (userId) => {

// }
